.footer {
  &__inner {
    height: 80px;
    display: flex;

    @media only screen and (min-width: 600px) {
      justify-content: flex-end;
    }
  }

  .menu {
    width: 100%;
    display: flex;
    align-items: center;

    @media only screen and (min-width: 600px) {
      width: auto;
    }

    &__list {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &__listItem {
      --size: 40px;

      height: var(--size);
      width: var(--size);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
    }

    &__link {
      color: inherit;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;

      &[title="gmail.com" i] {
        --hover-background-color: #ea4335;
      }

      &[title="skype.com" i] {
        --hover-background-color: #00aff0;
      }

      &[title="linkedin.com" i] {
        --hover-background-color: #006192;
      }

      &[title="github.com" i] {
        --hover-background-color: #666666;
      }

      &[title="codepen.io" i] {
        --hover-background-color: #ae63e4;
      }

      &[title="stackoverflow.com" i] {
        --hover-background-color: #f48024;
      }

      &:hover {
        background-color: var(--hover-background-color);
        color: #fff;
      }
    }
  }
}
