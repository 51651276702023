body {
  --color-bg: #fff;
  --color-fg: #333;

  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--color-bg);
  color: var(--color-fg);
}

body[data-scheme="dark"] {
  --color-bg: #202124;
  --color-fg: #e8eaed;

  .link__children {
    background-image: linear-gradient(
      to bottom,
      transparent 90%,
      var(--accent) 0
    );
  }

  .menu__listItem:not(.menu__listItem--active),
  .horizontalList__item,
  p {
    font-weight: 300;
  }

  .horizontalList__item,
  .projects__note {
    color: #bfbfbf;
  }

  .toast__content {
    color: #333;
  }

  @for $i from 1 through 6 {
    h#{$i} {
      font-weight: 500;
    }
  }
}

::-webkit-scrollbar {
  width: 18px;
}

::-webkit-scrollbar-thumb {
  background-color: #ffeb6c;
  background-clip: padding-box;
  border: 4px solid transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #f7d708;
}
