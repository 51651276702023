.header {
  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;

    @media only screen and (min-width: 600px) {
      justify-content: space-between;
    }
  }

  .menu {
    height: 100%;
    display: flex;
    align-items: center;

    &__list,
    &__listItem,
    &__link {
      height: inherit;
      display: inherit;
      align-items: inherit;
    }

    &__listItem {
      &:not(:last-child) {
        margin-right: 30px;
      }
    }

    &__listItem--active {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: #f7d708;
      }
    }

    &__link {
      font-size: 16px;
      color: var(--color-fg, inherit);
    }

    &__button {
      padding: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;
      height: 100%;
      width: 45px;
    }
  }
}

.title {
  display: none;
  text-decoration: none;
  color: inherit;

  @media only screen and (min-width: 600px) {
    display: inline-block;
  }

  h1 {
    margin: 0;
    font-size: 18px;
    color: var(--color-fg, inherit);
  }
}
