// source: https://codepen.io/stalingino/pen/rzxYzO

.loader {
  width: 15px;
  height: 15px;
  color: #f7d708;
  border: 4px solid;
  animation: loader 2s infinite ease;

  &::after {
    content: "";
    display: inline-block;
    vertical-align: top;
    width: 100%;
    background-color: currentColor;
    animation: loader-inner 2s infinite ease-in;
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
